import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import LocationManager from '@/utils/locationManager';

import { vMaska } from 'maska/vue';
import { IonicVue } from '@ionic/vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/buttons.css';
import './theme/inputs.css';
import './theme/layout.css';
import './theme/typography.css';
import './theme/cheetah.css';
import './theme/other.css';

const app = createApp(App)
    .use(IonicVue, {
        innerHTMLTemplatesEnabled: true,
    })
    .use(router)
    .use(store)
    .use(VueReCaptcha, { siteKey: process.env.VUE_APP_CAPTCHA_KEY, loaderOptions: { autoHideBadge: true } })
    .directive('maska', vMaska);

//@ts-ignore
if (window.Cypress) {
    //@ts-ignore
    window.store = store;
}

LocationManager.getInstance(); // Initialize location manager

router.isReady().then(() => {
    app.mount('#app');
});
