<template>
    <ion-grid>
        <ion-row>
            <ion-col size="12">
                <ion-item lines="none">
                    <ion-toggle label-placement="start" mode="ios" color="success" v-model="dealsAndProductEmails" justify="space-between">
                        <span class="left-label">Deals and New Product Emails</span>
                    </ion-toggle>
                </ion-item>
                <ion-radio-group v-if="dealsAndProductEmails" :value="selectedDeal" mode="md" @ionChange="updateSelectedDeal($event.detail.value)">
                    <ion-item v-for="(deal, i) in deals" :key="deal" lines="none">
                        <ion-radio :disabled="!dealsAndProductEmails" :value="i" label-placement="end" justify="start">
                            <span class="ion-text-wrap sel-option">{{ deal }}</span>
                        </ion-radio>
                    </ion-item>
                </ion-radio-group>
            </ion-col>
            <ion-col size="12">
                <ion-item lines="none">
                    <ion-toggle mode="ios" color="success" v-model="commPrefs.receivePersonalizedOffers" @ionChange="updatePrefs('receivePersonalizedOffers', commPrefs.receivePersonalizedOffers)">
                        <span class="left-label">Reward Emails</span>
                    </ion-toggle>
                </ion-item>
                <ion-item>
                    <ion-toggle mode="ios" color="success" v-model="commPrefs.lrmEmails" @ionChange="updatePrefs('lrmEmails', commPrefs.lrmEmails)">
                        <span class="left-label">Catering Promotions</span>
                    </ion-toggle>
                </ion-item>
                <ion-item>
                    <ion-toggle mode="ios" color="success" v-model="commPrefs.receiveSmsOffers" @ionChange="updatePrefs('receiveSmsOffers', commPrefs.receiveSmsOffers)">
                        <span class="left-label"
                            >Text Messages
                            <span v-if="account?.phoneNumber"
                                ><ion-text>to {{ formattedPhoneNumber }}</ion-text></span
                            ></span
                        >
                    </ion-toggle>
                </ion-item>
            </ion-col>
        </ion-row>
    </ion-grid>
</template>

<script lang="ts">
import { defineComponent, ref, PropType, watch, computed } from 'vue';
import { IonRow, IonCol, IonRadioGroup, IonItem, IonRadio, IonToggle, IonText, IonGrid } from '@ionic/vue';
import { arrowForwardOutline } from 'ionicons/icons';
import { Account } from '@/models/account/authModels';
import CommunicationPreferences from '@/models/account/communicationPreferences';
import { updateCommunicationPreferences } from '@/services/accountService';
import { formatPhoneNumber } from '@/services/stringService';

export default defineComponent({
    props: { account: Object as PropType<Account> },
    components: { IonRow, IonCol, IonRadioGroup, IonItem, IonRadio, IonToggle, IonText, IonGrid },
    setup(props) {
        const formattedPhoneNumber = computed(() => {
            return props.account?.phoneNumber ? formatPhoneNumber(props.account.phoneNumber) : '';
        });
        const deals = ['Everything', 'Only Deals and New Menu Items (about 1x per week)'];
        const selectedDeal = ref(-1);

        //Used to temporarily disable watch when API call fails
        const isReset = ref(false);

        const commPrefs = ref(new CommunicationPreferences(props.account));
        watch(
            commPrefs,
            (newVal) => {
                if (newVal.ltdCommunicationEmail) selectedDeal.value = 1;
                if (newVal.receiveDonatosNewsAndEventEmails) selectedDeal.value = 0;
            },
            { deep: true, immediate: true }
        );

        //SELECTED DEAL RADIO GROUP
        const updateSelectedDeal = (newValue) => {
            selectedDeal.value = newValue;
            if (selectedDeal.value == 0) updatePrefs('receiveDonatosNewsAndEventEmails', true);
            if (selectedDeal.value == 1) updatePrefs('ltdCommunicationEmail', true);
        };
        watch(selectedDeal, (newVal) => {
            updateSelectedDeal(newVal);
        });

        const dealsAndProductEmails = ref(commPrefs.value.receiveDonatosNewsAndEventEmails || commPrefs.value.ltdCommunicationEmail);
        watch(dealsAndProductEmails, (newVal) => {
            //If toggling off, set both to false
            if (!newVal) {
                if (commPrefs.value.receiveDonatosNewsAndEventEmails) {
                    updatePrefs('receiveDonatosNewsAndEventEmails', false);
                    commPrefs.value.receiveDonatosNewsAndEventEmails = false;
                    selectedDeal.value = -1;
                }
                if (commPrefs.value.ltdCommunicationEmail) {
                    updatePrefs('ltdCommunicationEmail', false);
                    commPrefs.value.ltdCommunicationEmail = false;
                    selectedDeal.value = -1;
                }
            }
            //If toggling on, set "Everything" to true
            else {
                updatePrefs('receiveDonatosNewsAndEventEmails', true);
                commPrefs.value.receiveDonatosNewsAndEventEmails = true;
            }
        });

        const updatePrefs = (prefName: string, value: boolean) => {
            if (!isReset.value) {
                let requestObj = { customerId: props.account?.customerId };
                requestObj[prefName] = +value;
                updateCommunicationPreferences(requestObj).catch(() => {
                    isReset.value = true;
                    commPrefs.value[prefName] = !value;
                });
            }
            isReset.value = false;
        };

        return { formattedPhoneNumber, deals, selectedDeal, arrowForwardOutline, commPrefs, dealsAndProductEmails, updatePrefs, updateSelectedDeal };
    },
});
</script>

<style scoped>
.left-label {
    font-weight: 600;
    color: var(--ion-color-dark-shade);
}

.right-aligned-icon {
    margin-left: auto;
    color: var(--ion-color-medium-shade);
}

.sel-option {
    font-family: 'Roboto', 'Helvetica Neue', sans-serif;
}

ion-item-divider {
    min-height: 1px;
}

ion-button {
    --background: transparent;
    --background-activated: transparent;
    --padding-start: 0px;
    --box-shadow: none;
    width: 100%;
    text-align: left;
    font-weight: 600;
}
</style>
