<template>
    <ion-input
        :label-placement="stacked ? 'stacked' : 'floating'"
        :fill="fill"
        placeholder="(555) 867-5308"
        inputmode="tel"
        v-model="formattedPhoneNumber"
        maxLength="14"
        autocomplete="tel"
        type="tel"
        v-maska="'(###) ###-####'"
        @input="handlePhoneUpdated"
        color="medium"
        :error-text="error"
        class="custom"
        mode="md"
    >
        <div slot="label" class="label-color">{{ label ?? 'Phone Number' }} <ion-text v-if="required" color="danger">*</ion-text></div>
    </ion-input>
</template>

<script lang="ts">
import { IonInput, IonText } from '@ionic/vue';
import { computed, defineComponent, PropType } from 'vue';
import { formatPhoneNumber } from '@/services/stringService';

/**
 * For area code validation with "yup" add this to schema->
 * `.test("areaCode", "Phone number area code is not valid", (value) => isAreaCodeValid(value))
 */
export default defineComponent({
    props: {
        label: String,
        required: Boolean,
        modelValue: { type: String, default: '' },
        fill: String as PropType<'outline' | 'solid' | undefined>,
        labelColor: String,
        dense: Boolean,
        stacked: Boolean,
        mode: String,
        error: String,
    },
    setup(props, { emit }) {
        const formattedPhoneNumber = computed(() => formatPhoneNumber(props.modelValue));
        const handlePhoneUpdated = (evt: Event) => {
            const target = evt.target as HTMLInputElement;
            emit('update:modelValue', target.value);
        };
        return {
            formattedPhoneNumber,
            handlePhoneUpdated,
        };
    },
    components: { IonInput, IonText },
});
</script>

<style scoped>
ion-input.custom {
    --placeholder-color: #92949c;
}
.label-color {
    color: #92949c;
}
</style>
